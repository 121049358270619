import { LitElement, html, css, PropertyValues } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './app-unifiedtak';
@customElement('unified-talk')
class UnifiedTalk extends LitElement {
  // static styles=css`
  // .fab-container{z-index: 99999999999999999999999;position:fixed; bottom:20px; display:flex; justify-content:flex-start; align-items:center;}
  // .fab-container-left{left:20px;}
  // .fab-container-right{right:20px;}
  // .popup-container{position:fixed; bottom:100px; border-radius:10px; right:40px; width:360px; height:450px; background-color:#fff; 
  // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  // z-index:99999;display:flex;
  // flex-direction:column;
  // justify-content:space-between;
  // padding:0px;
  // transition:all 0.3s ease;
  // }
  // iframe{
  // border:solid 0px #cecece;
  // width:100%; border-radius:4px; margin:0; display:block; padding:0; height:100vh; 
  // }
  // .popup-content{flex:1; border-radius:10px; border:0; margin:0; padding:0;}`;
  @property({ type: Number, reflect: true }) icontype = 0;
  @property({ type: Boolean }) showPopup = false;
  @property({ type: Boolean }) alignRight = true;
  @property({ type: String }) merchantId = '';
  @property({ type: String }) isDebug = 'false';
  @property({ type: String }) language = 'ar';
  @property({ type: Boolean }) isShowIcon = true;
  icons=[
    {id:1,url:'https://chat.unifiedtalk.com/assets/images/icon1.svg'},
    {id:2,url:'https://chat.unifiedtalk.com/assets/images/icon2.svg'},
    {id:3,url:'https://chat.unifiedtalk.com/assets/images/icon3.svg'},
    {id:5,url:'https://chat.unifiedtalk.com/assets/images/icon5.svg' },
    {id:6,url:'https://chat.unifiedtalk.com/assets/images/icon6.svg' },
    {id:7,url:'https://chat.unifiedtalk.com/assets/images/icon7.svg' },
    {id:8,url:'https://chat.unifiedtalk.com/assets/images/icon8.svg' },
  ];
  currentImageIndex = 0;
  currentImage = this.icons[this.currentImageIndex];
  constructor() {
    super();
    if(sessionStorage.getItem('ispopshow')){
      this.showPopup=true;
      this.isShowIcon=false;
    }else{
      this.showPopup=false;
      this.isShowIcon=true;
    }
  }
  protected firstUpdated(): void {
        // Add event listener for custom event
        this.addEventListener('data-from-unified-tak', this.handleAppUnifiedtak as EventListener);
  }
  connectedCallback(){
    super.connectedCallback();
    this.icontype -= 1;
    this.currentImage = this.icons[this.icontype];
  }
  disconnectedCallback(){
    super.disconnectedCallback();
  }
    // Event listener for custom event from app-unifiedtak
    handleAppUnifiedtak(event: CustomEvent) {
      if(event.detail.value.ActionCode=='Close'){
        this.closePopup();
        this.isShowIcon=true;
        sessionStorage.removeItem('ispopshow');
      }
      else if(event.detail.value.ActionCode=='ConfigRequest'){
        this.isShowIcon=false;
        sessionStorage.setItem('ispopshow', 'true');
      }

    }
  show(){
    this.showPopup = true;
  }
  closePopup(){
    this.showPopup=false;
  }
  render(){
    return html`
    <style>
 .fab-container{z-index: 99999999999999999999999;position:fixed; bottom:20px; display:flex; justify-content:flex-start; align-items:center;}
  .fab-container-left{left:20px;}
  .fab-container-right{right:20px;}
  .popup-container{position:fixed; bottom:20px; border-radius:10px; right:10px; width:360px; height:600px; background-color:#fff; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index:99999;display:flex;
  flex-direction:column;
  justify-content:space-between;
  padding:0px;
  transition:all 0.3s ease;
  }
  @media screen and (max-width:675px) {
 .popup-container {
    width: 95%;
}
}
  iframe{
  border:solid 0px #cecece;
  width:100%; border-radius:4px; margin:0; display:block; padding:0; height:100vh; 
  }
  .popup-content{flex:1; border-radius:10px; border:0; margin:0; padding:0;}
    </style>
    <div class="fab-container ${this.alignRight ? 'fab-container-right' : 'fab-container-left'}">
        ${this.isShowIcon
          ? html`
              <a @click="${this.show}">
                <div>
                  <img src="${this.currentImage.url}" alt="image" width="70" height="70" />
                </div>
              </a>
            `
          : ''}
    ${this.showPopup?html`
      <div class="popup-container">
      <div class="popup-content">
      <app-unifiedtak></app-unifiedtak>
      </div>
      </div>`:''}
      </div>`;}
}
