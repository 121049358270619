import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
@customElement('app-unifiedtak')
class UnifiedtakComponent extends LitElement {
  @property({type:String,attribute:'merchant-id'}) merchantId='';
  @property({type:String,attribute:'is-debug'}) isDebug='false';
  @property({type:String,attribute:'language'})language='ar';
  @property({type:String})BaseUrl:string="https://chat.unifiedtalk.com";
  @property({type:String})frameUrl:string='';
  constructor(){super();}
  firstUpdated(){
    if(this.isDebug==='true'){
      this.BaseUrl='http://localhost:8000/';
    }
    this.frameUrl=this.BaseUrl;
  }

      // Method to emit custom event
      sendDataToParent(data:any) {
        const detailData = {value: data };
        this.dispatchEvent(new CustomEvent('data-from-unified-tak', {
          detail: detailData,
          bubbles: true, // Allow the event to bubble up to the parent
          composed: true // Allow the event to cross shadow DOM boundaries
        }));
      }
  handleWindowMessage(event:MessageEvent){
    const receivedData=event.data;
    if (!event.data.ActionCode) return;
    const iframe=this.shadowRoot!.querySelector('iframe') as HTMLIFrameElement;
    if (event.data.ActionCode === 'ConfigRequest') {
      const dataToSend={merchantId:this.merchantId,lang:this.language,};
      iframe.contentWindow!.postMessage(dataToSend, '*');
      this.sendDataToParent(receivedData);
    }
    else if(event.data.ActionCode==='Close')
      this.sendDataToParent(receivedData);
    else{console.log('SDK Error Event without Handled');}
    console.log('Data received in parent:', receivedData);
  }
  connectedCallback(){
    super.connectedCallback();
    window.addEventListener('message', this.handleWindowMessage.bind(this));
  }
  disconnectedCallback(){
    window.removeEventListener('message', this.handleWindowMessage.bind(this));
    super.disconnectedCallback();
  }
  render(){
    return html`
    <iframe id="myFrame" src="${this.frameUrl}" scrolling="No" style="border: solid 0px #cecece; width: 100%; border-radius: 10px; margin: 0; display: block; padding: 0; height: 600px;">
    </iframe>`;
  }
  static styles = css``;
}